import { Flex, Heading, SimpleGrid, Text } from '@chakra-ui/react'
import NextImage from 'next/image'
import NextLink from 'next/link'
import { useState } from 'react'

import { FrontCollectTypeType, FrontSearchProjectType } from '@miimosa/common'
import { Icon } from '@miimosa/design-system'

interface ProjectCardProps {
  slug: string
  imageUrl?: string | null
  title: string
  place: string
  state: string
  collectedAmount: number
  description: string
  progress: number
  collectType: FrontCollectTypeType
}

const ProjectCard = ({
  slug,
  imageUrl,
  title,
  place,
  state,
  collectedAmount,
  description,
  progress,
  collectType,
}: ProjectCardProps) => {
  const [hover, setHover] = useState<boolean>(false)

  return (
    <Flex w="full" justifyContent="center">
      <Flex h="612px" w={{ base: 'full', md: '361px' }} bg="white" justifyContent="center" direction={'column'}>
        <NextLink href={`${process.env.NEXT_PUBLIC_SERVER_ENDPOINT_V2}/projects/${slug}`} target="_blank">
          <Flex
            w="full"
            h="246px"
            position="relative"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            justifyContent="center"
            alignItems="center"
          >
            {imageUrl && (
              <NextImage
                src={imageUrl}
                alt=""
                fill
                style={{ objectFit: 'cover' }}
                sizes={'(max-width: 600px) 100vw, 50vw, (max-width: 100px) 33vw'}
              />
            )}
            {hover && (
              <Flex
                direction="column"
                justifyContent="center"
                alignItems="center"
                bg="#d509118c"
                zIndex={3}
                h="full"
                w="full"
              >
                <Icon name={'CirclePlus'} size="sm" color="white" />
                <Text color="white">Je découvre le projet</Text>
              </Flex>
            )}
          </Flex>
        </NextLink>
        <Flex
          direction="column"
          px={6}
          py={8}
          rowGap={3}
          border="1px solid"
          borderColor="gray.200"
          borderBottomRadius={25}
        >
          <Text size="2xl" noOfLines={2} minH="60px">
            {title}
          </Text>
          <Flex direction="row" alignItems="center" columnGap={1}>
            <Icon name="PinMap" size="5xs" />
            <Text size="sm">{place}</Text>
          </Flex>
          <Flex w="full" h="26px" border="1px solid" borderColor="cream">
            <Flex
              bg={state == 'funded' ? 'green' : 'yellow'}
              w="fit-content"
              h="full"
              alignItems="center"
              justifyContent="center"
              px={3}
            >
              <Text size="sm" whiteSpace="nowrap" color="white">
                {state == 'funded' ? 'Terminé' : 'En cours'}
              </Text>
            </Flex>
            <Flex w="full" alignItems="center" justifyContent="center">
              <Text size="sm">{collectType == 'lending' ? 'Prêt rémunéré' : 'Don avec contrepartie'}</Text>
            </Flex>
          </Flex>
          <Flex w="full" h="7px" bg="gray.200" borderRadius={'50'}>
            <Flex h="full" w={`${progress}%`} bg="cream" borderLeftRadius={'50'} borderRightRadius={'50'} />
          </Flex>
          <Flex direction="row" w="full" justifyContent="space-between">
            <Text size="xs">
              {new Intl.NumberFormat('fr-FR', {
                style: 'currency',
                currency: 'EUR',
                maximumFractionDigits: 0,
              }).format(collectedAmount)}{' '}
              collectés
            </Text>
            <Text size="xs" color="red">
              {progress}%
            </Text>
          </Flex>
          <Text size="sm" noOfLines={6} color="gray.200" minHeight="115px">
            {description}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}

const Projects = ({ projects }: { projects: FrontSearchProjectType[] }) => {
  return (
    <Flex id="projects" w="full" h="auto" direction="column" py={14} px={16} rowGap={8}>
      <Heading size="lg" textAlign="center" color="red">
        Les projets soutenus
      </Heading>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
        {projects.map(({ slug, title, place, state, collect, shortDescription, imageFileName }) => (
          <ProjectCard
            key={slug}
            slug={slug}
            imageUrl={imageFileName}
            title={title}
            place={place}
            state={state}
            collectedAmount={collect?.collectedAmount || 0}
            description={shortDescription}
            progress={collect?.progress || 0}
            collectType={collect?.type || 'donation'}
          />
        ))}
      </SimpleGrid>
    </Flex>
  )
}

export default Projects
