import { Flex } from '@chakra-ui/react'
import NextImage from 'next/image'

import { s3ImageURL } from '@miimosa/common'

import CollapsedBloc from './CollapsedBloc'

const HowItWorks = () => {
  return (
    <Flex bg="white" w="full" h="auto" py={14} px={16} direction={{ base: 'column-reverse', md: 'row' }} columnGap={16}>
      <Flex direction="column" w="full" rowGap={6} py={10}>
        <CollapsedBloc
          title={'Comment ça marche ?'}
          data={[
            '- Vous êtes un agriculteur Mousline.',
            '- Vous portez un projet pour la transition de la filière pommes de terre qui nécessite un financement.',
            '- Candidatez via le formulaire d’inscription :\n MiiMOSA revient vers vous et vous conseille la solution de financement participatif la plus adaptée à votre projet.',
            "- Lancez votre campagne de financement participatif tout au long de l'année 2024 : MiiMOSA vous accompagne lors de votre collecte.",
            '- Deux comités de sélection Mousline sélectionnent les 6 projets ayant le plus d’impact pour leur apporter un soutien financier pouvant aller jusqu’à 10 000€.',
            '- Les comités de sélection auront lieu : le premier en septembre 2024 et le second en décembre 2024.',
            '- Les dotations Mousline sont versées aux projets sélectionnés suite aux comités de sélection.',
          ]}
        />
        <CollapsedBloc
          title={'Les avantages'}
          data={[
            '- Financement de votre projet grâce au grand public',
            '- Versement d’une dotation de 10 000€ si vous êtes sélectionné',
            '- Développement de votre notoriété',
            '- Communication renforcée auprès des communautés Mousline et MiiMOSA',
          ]}
        />
      </Flex>
      <Flex h="340px" w="full" maxW="500px" position="relative" direction="column" alignItems="center">
        <Flex
          w="full"
          h="full"
          bg="red"
          transform={'translateX(-20px) translateY(20px)'}
          borderTopLeftRadius="20px"
          borderBottomRightRadius="20px"
        />
        <NextImage
          src={s3ImageURL('pommeterrebucket.png')}
          alt={'pomme de terre bucket'}
          fill
          style={{ objectFit: 'cover', borderTopLeftRadius: '20px', borderBottomRightRadius: '20px' }}
          sizes={'(max-width: 1200px) 50vw, 33vw'}
        />
      </Flex>
    </Flex>
  )
}

export default HowItWorks
