import { Flex, Text, useDisclosure, Collapse } from '@chakra-ui/react'
import { useState } from 'react'

const CollapsedBloc = ({ title, data }: { title: string; data: string[] }) => {
  const { isOpen, onToggle } = useDisclosure()
  const [bgColor, setBgColor] = useState('cream')

  const changeBgColorAndSetonToggle = () => {
    setBgColor(bgColor == 'white' ? 'cream' : 'white')
    onToggle()
  }
  return (
    <Flex bg={bgColor} direction="column" border="solid 1px" borderColor="gray.200">
      <Flex
        direction="row"
        alignItems="center"
        py={4}
        px={4}
        justifyContent="space-between"
        _hover={{ cursor: 'pointer' }}
        onClick={() => changeBgColorAndSetonToggle()}
      >
        <Text size="sm" fontWeight={700} color="gray.800">
          {title}
        </Text>
        <Text size="sm" fontWeight={700} color="gray.800">
          {isOpen ? '-' : '+'}
        </Text>
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <Flex direction="column" py={4} px={4} rowGap={2}>
          {data.map((text, i) => (
            <Text fontSize="12px" color="gray.200" key={`text_${i}`} whiteSpace="pre-wrap">
              {text}
            </Text>
          ))}
        </Flex>
      </Collapse>
    </Flex>
  )
}

export default CollapsedBloc
