import {
  Flex,
  Heading,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Button,
  SimpleGrid,
  Select,
  Textarea,
  CheckboxGroup,
  Checkbox,
  Spinner,
  Text,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useForm, SubmitHandler, Controller, FormProvider, useFormContext, FieldError } from 'react-hook-form'

type Inputs = {
  lastname: string
  firstname: string
  email: string
  mobile: string
  exploitation: string
  code: string
  collectType: string
  projectTotalBudget: number
  description: string
  theme: string[]
}

const FormField = ({
  name,
  type,
  isInvalid,
  displayName,
  errors,
}: {
  name: keyof Inputs
  type: 'text' | 'number' | 'tel' | 'email'
  isInvalid: boolean
  displayName: string
  errors?: FieldError
}) => {
  const { register } = useFormContext()

  return (
    <FormControl isInvalid={isInvalid}>
      <FormLabel htmlFor={name}>{displayName}*</FormLabel>
      <Input
        px={2}
        type={type}
        bg="white"
        h="50px"
        {...(type == 'number'
          ? { ...register(name, { required: true, max: 10000000, min: 10 }) }
          : { ...register(name, { required: true, maxLength: 100, minLength: 1 }) })}
      />
      <FormErrorMessage color="red">Ce champ est requis</FormErrorMessage>
      {(type == 'text' || type == 'tel' || type == 'email') && errors?.type == 'maxLength' && (
        <Text color="red">Ce champ ne doit pas faire plus de 100 caractères.</Text>
      )}
      {(type == 'text' || type == 'tel' || type == 'email') && errors?.type == 'minLength' && (
        <Text color="red">Ce champ doit au minimum faire 1 caractère.</Text>
      )}
      {type == 'number' && errors?.type == 'min' && (
        <Text color="red">Ce champ ne doit pas avoir de valeur inférieure ou égale à 0.</Text>
      )}
      {type == 'number' && errors?.type == 'max' && (
        <Text color="red">Ce champ ne doit pas avoir de valeur supérieur à 10 000 000.</Text>
      )}
    </FormControl>
  )
}

const Form = () => {
  const methods = useForm<Inputs>()

  const [spinner, setSpinner] = useState<boolean>(false)
  const [submittedText, setSubmittedText] = useState({ text: '', color: '', success: false })

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setSpinner(true)
    const response = await fetch('/nextapi/leads', {
      credentials: 'same-origin',
      method: 'POST',
      body: JSON.stringify({
        email: data.email,
        firstname: data.firstname,
        lastname: data.lastname,
        phone: data.mobile,
        exploitation: data.exploitation,
        code: data.code,
        collectType: data.collectType,
        description: data.description,
        theme: data.theme,
        projectTotalBudget: data.projectTotalBudget,
      }),
    })

    if (response.status === 200) {
      setSpinner(false)
      setSubmittedText({
        text: 'Merci pour votre projet ! Un de nos experts vous rappellera dès que possible.',
        color: 'black',
        success: true,
      })
    } else {
      setSpinner(false)
      setSubmittedText({
        text: 'Un problème est survenu, veuillez nous contacter à bonjour@miimosa.com.',
        color: 'red',
        success: false,
      })
    }
  }

  const [isChecked, setIsChecked] = useState(false)

  return (
    <FormProvider {...methods}>
      <Flex
        w="full"
        h="auto"
        bg="cream"
        py={14}
        px={16}
        direction="column"
        as="form"
        onSubmit={methods.handleSubmit(onSubmit)}
        rowGap={8}
        id="form"
      >
        <Heading color="red" size="lg" textAlign="center">
          Formulaire de candidature
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          <FormField
            name={'lastname'}
            displayName="Nom de famille"
            type={'text'}
            isInvalid={methods.formState.errors.lastname?.type == 'required'}
            errors={methods.formState.errors.lastname}
          />
          <FormField
            name={'firstname'}
            displayName="Prénom"
            type={'text'}
            isInvalid={methods.formState.errors.firstname?.type == 'required'}
            errors={methods.formState.errors.firstname}
          />
          <FormField
            name={'email'}
            displayName="Email"
            type={'email'}
            isInvalid={methods.formState.errors.email?.type == 'required'}
            errors={methods.formState.errors.email}
          />
          <FormField
            name={'mobile'}
            displayName="Téléphone"
            type={'tel'}
            isInvalid={methods.formState.errors.mobile?.type == 'required'}
            errors={methods.formState.errors.mobile}
          />
          <FormField
            name={'exploitation'}
            displayName="Nom d'exploitation"
            type={'text'}
            isInvalid={methods.formState.errors.exploitation?.type == 'required'}
            errors={methods.formState.errors.exploitation}
          />
          <FormField
            name={'code'}
            displayName="Commune / Code postal"
            type={'text'}
            isInvalid={methods.formState.errors.code?.type == 'required'}
            errors={methods.formState.errors.code}
          />

          <FormControl isInvalid={methods.formState.errors.collectType?.type == 'required'}>
            <FormLabel>Vous souhaitez réaliser*</FormLabel>
            <Controller
              name="collectType"
              control={methods.control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <>
                  <Select
                    {...field}
                    placeholder="Séléctionnez une valeur ci-dessous"
                    bg={'white'}
                    h="50px"
                    borderRadius="unset"
                  >
                    <option value="lending">Un prêt (pour un montant minimum de 20 000€)</option>
                    <option value="donation">
                      Une collecte en don avec contrepartie (pour un montant minimum de 3 000 €)
                    </option>
                  </Select>
                  <FormErrorMessage color="red">Ce champ est requis</FormErrorMessage>
                </>
              )}
            />
          </FormControl>
          <FormField
            name={'projectTotalBudget'}
            displayName="Montant du besoin de financement en euros"
            type={'number'}
            isInvalid={methods.formState.errors.projectTotalBudget?.type == 'required'}
          />
        </SimpleGrid>
        <FormControl isInvalid={methods.formState.errors.description?.type == 'required'}>
          <FormLabel>Descriptif projet*</FormLabel>
          <Textarea
            bg="white"
            h="130px"
            {...methods.register('description', { required: true, maxLength: 1000, minLength: 1 })}
          />
          <FormErrorMessage color="red">Ce champ est requis</FormErrorMessage>
          {methods.formState.errors.description?.type == 'maxLength' && (
            <Text color="red">Ce champ ne doit pas faire plus de 1000 caractères.</Text>
          )}
          {methods.formState.errors.description?.type == 'minLength' && (
            <Text color="red">Ce champ doit au minimum faire 1 caractère.</Text>
          )}
        </FormControl>
        <FormControl>
          <FormLabel mb={4}>Thématique(s) :</FormLabel>
          <Controller
            name="theme"
            control={methods.control}
            render={({ field: { onChange, value, name } }) => (
              <CheckboxGroup colorScheme="checkbox" onChange={onChange} value={value}>
                <Flex direction={{ base: 'column', md: 'row' }} columnGap={20} rowGap={4}>
                  <Flex direction={'column'} rowGap={4}>
                    <Checkbox value="carbone" name={name}>
                      Réduction de l&apos;émission de carbone
                    </Checkbox>
                    <Checkbox value="transport" name={name}>
                      Optimisation du transport de la pomme de terre
                    </Checkbox>
                    <Checkbox value="water" name={name}>
                      Réduction de l&apos;usage de l&apos;eau
                    </Checkbox>
                  </Flex>
                  <Flex direction={'column'} rowGap={4}>
                    <Checkbox value="ground" name={name}>
                      Régénérescence des sols
                    </Checkbox>
                    <Checkbox value="knowledge" name={name}>
                      Transmission du savoir-faire agricole de la pomme de terre / de l&apos;entreprise
                    </Checkbox>
                    <Checkbox value="other">Autre</Checkbox>
                  </Flex>
                </Flex>
              </CheckboxGroup>
            )}
          />
        </FormControl>
        <Checkbox colorScheme="checkbox" isChecked={isChecked} onChange={() => setIsChecked(!isChecked)}>
          J’ai compris que l’obtention des avantages est conditionnée à la réussite de ma campagne de financement
          participatif sur MiiMOSA et au fait d’être sélectionné par Mousline.*
        </Checkbox>
        {spinner ? (
          <Flex w="full" justifyContent="center">
            <Spinner />
          </Flex>
        ) : (
          <Flex direction="column">
            <Text color={submittedText.color}>{submittedText.text}</Text>
            <Button
              type="submit"
              bg="yellow"
              w="fit-content"
              alignSelf={{ base: 'center', md: 'flex-end' }}
              isDisabled={!isChecked}
            >
              Envoyer
            </Button>
          </Flex>
        )}
      </Flex>
    </FormProvider>
  )
}

export default Form
