import { Flex, Heading, Text, useBreakpointValue } from '@chakra-ui/react'
import NextImage from 'next/image'

import { s3ImageURL } from '@miimosa/common'
import { Icon, IconName } from '@miimosa/design-system'

const IconBloc = ({ iconName, title }: { iconName: IconName; title: string }) => {
  return (
    <Flex
      direction="column"
      w="300px"
      h="270px"
      bg="white"
      borderTopLeftRadius="20px"
      borderBottomRightRadius="20px"
      justifyContent="center"
      alignItems="center"
      px={6}
      zIndex={1}
    >
      <Flex direction="column" alignItems="center" rowGap={4}>
        <Flex alignItems="center" justifyContent="center" bg="cream" borderRadius="50%" w="114px" h="114px">
          <Icon name={iconName} size="sm" color="red" />
        </Flex>
        <Text color="gray.800" textTransform="uppercase" textAlign="center" size="lg">
          {title}
        </Text>
      </Flex>
    </Flex>
  )
}

const Engagements = () => {
  const isLargeScreen = useBreakpointValue({ base: false, xl: true }, { fallback: 'xl' })

  return (
    <Flex
      id="engagements"
      w="full"
      h="auto"
      bg="cream"
      py={14}
      direction="column"
      rowGap={8}
      px={{ base: 16, md: 28 }}
      position="relative"
    >
      <Flex w="full" direction="column" alignItems="center" rowGap={4}>
        <Heading color="red" size="lg" textAlign="center">
          Nos engagements pour la filière
        </Heading>
        <Flex direction="column" alignItems="center">
          <Text size="lg" color="gray.200" textAlign="center">
            Mousline souhaite accompagner ses agriculteurs dans l&apos;accélération de la transition agricole grâce à un
            soutien financier.
          </Text>
          <Text size="lg" color="gray.200" textAlign="center">
            L&apos;objectif est d’aider des projets s&apos;inscrivant dans une ou plusieurs de ces 5 thématiques :
          </Text>
        </Flex>
      </Flex>
      <Flex direction="row" columnGap={16} rowGap={8} justifyContent="center" flexWrap="wrap">
        <IconBloc iconName={'LogoCo2'} title={"Réduction de l'emission de carbone"} />
        <IconBloc iconName={'Tractor'} title={'Optimisation du transport de la pomme de terre'} />
        <IconBloc iconName={'WaterMousline'} title={"Réduction de l'usage de l'eau"} />
        {/* used to force newline on large screen, to keep the 2*3 style */}
        {isLargeScreen && <Flex w="full" />}
        <IconBloc iconName={'ArrowMousline'} title={'Régénérescence des sols'} />
        <IconBloc
          iconName={'SeedMousline'}
          title={"Transmission du savoir-faire agricole de la pomme de terre / de l'entreprise"}
        />
      </Flex>
      <Flex
        position="absolute"
        bottom={0}
        right="50px"
        w={{ base: '200px', md: '400px' }}
        h={{ base: '200px', md: '300px' }}
      >
        <NextImage
          src={s3ImageURL('pommeterre.png')}
          alt={'pomme de terre'}
          fill
          style={{ objectFit: 'contain', zIndex: '0' }}
          sizes={'(max-width: 1200px) 50vw, 33vw'}
        />
      </Flex>
    </Flex>
  )
}

export default Engagements
