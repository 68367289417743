import { Flex, Heading } from '@chakra-ui/react'

import CollapsedBloc from './CollapsedBloc'

const Faq = () => {
  return (
    <Flex w="full" h="auto" bg="white" py={14} px={16} rowGap={8} direction="column">
      <Heading size="lg" textAlign="center" color="red">
        Foire aux questions
      </Heading>
      <Flex w="full" direction={{ base: 'column', md: 'row' }} rowGap={8} columnGap={8}>
        <Flex direction="column" w="full" rowGap={8}>
          <CollapsedBloc
            title={'Pourquoi Mousline s’engage ?'}
            data={[
              "Mousline s'engage pour que le plus grand nombre puisse se nourrir de manière saine et accessible. Cet appel à projet vise à soutenir les agriculteurs de pommes de terre dans leur transition agricole et alimentaire. Concrètement, Mousline alloue une enveloppe de 60 000€ en dotations financières pour accompagner la filière pomme de terre dans le financement de projets structurants pour l’avenir.",
            ]}
          />
          <CollapsedBloc
            title={'Comment fonctionne l’opération ?'}
            data={[
              "Si vous êtes un agriculteur Mousline et que vous avez un projet de transition pour la filière pommes de terre qui nécessite un financement, n'hésitez pas à postuler via notre formulaire d’inscription. MiiMOSA vous guidera vers la meilleure solution de financement participatif adaptée à votre projet. Faites une campagne de financement participatif en 2024 avec l’accompagnement continu de MiiMOSA. Le jury Mousline sélectionne les six projets les plus impactants pour leur offrir un soutien financier pouvant atteindre 10 000€, fin 2024.",
            ]}
          />
          <CollapsedBloc
            title={'Comment candidater ?'}
            data={[
              'Pour candidater, il suffit de remplir les différentes informations requises du « Formulaire de candidature » plus haut sur cette page.\nVous serez recontacté sous 48h ouvrées par les équipes MiiMOSA.',
            ]}
          />
          <CollapsedBloc
            title={'Qu’est ce que le financement participatif ?'}
            data={[
              'Le financement participatif (crowdfunding en anglais) est un mode de financement permettant au grand public de soutenir financièrement et collectivement des projets qui le séduisent.\n MiiMOSA utilise deux offres de financement participatif : le don avec contrepartie et le prêt rémunéré. Le financement participatif permet également à des acteurs professionnels (investisseurs, entreprises, etc.) de soutenir les projets.',
            ]}
          />
          <CollapsedBloc
            title={'Quels sont les engagements de Mousline ?'}
            data={[
              'Mousline s’engage :\nÀ verser une dotation financière pouvant aller jusqu’à 10 000€ pour 6 projets sélectionnés\nÀ apporter de la visibilité à ses producteurs sélectionnés\nRenforcer le lien avec ses agriculteurs',
            ]}
          />
        </Flex>
        <Flex direction="column" w="full" rowGap={8}>
          <CollapsedBloc
            title={'Quels engagements pour le porteur de projets ?'}
            data={[
              'Le porteur de projet s’engage à :\nFaire une collecte en financement participatif\nRéaliser le projet pour lequel il est financé\nAccepter d’être identifié(e) comme porteur de projet participant à l’appel à projet',
            ]}
          />
          <CollapsedBloc
            title={'Que comprend la dotation pour les lauréats ?'}
            data={[
              'La dotation est un soutien financier de Mousline pouvant aller jusqu’à 10 000€ pour ses fournisseurs engagés et sélectionnés par le jury Mousline.',
            ]}
          />
          <CollapsedBloc
            title={'MiiMOSA, c’est quoi ?'}
            data={[
              'MiiMOSA est la plateforme de financement leader en Europe dédiée à la transition agricole et alimentaire. Après avoir accompagné 7 000 projets pour 150 millions d’euros de financement (en France et en Belgique), MiiMOSA a lancé en janvier 2022 le premier fonds de dette dédié à la thématique.',
              'Labellisée BCorp, l’entreprise s’engage à recréer du lien entre des porteurs de projet et l’ensemble de la société, afin que chacun participe à la résolution des enjeux alimentaires, environnementaux, climatiques, sanitaires et énergétiques.',
            ]}
          />
          <CollapsedBloc
            title={'Mousline, c’est quoi ?'}
            data={[
              'Mousline a révolutionné les cuisines des familles en 1963 avec la première purée de pommes de terre en flocons, pratique et rapide à préparer.\nAujourd’hui encore Mousline® répond aux besoins de praticité de toutes les familles, s’inscrivant ainsi comme votre partenaire idéal pour une purée de qualité, sans compromis sur la qualité. ',
              'La purée Mousline® est fabriquée à partir de pommes de terre françaises, rigoureusement sélectionnées et qui proviennent en grande majorité de champs situés à moins de 30 km de l’usine de Rosières en Santerre dans les Hauts-de-France. Plus de 120 producteurs partenaires locaux engagés de génération en génération avec la marque garantissent la qualité des pommes de terre. Les pommes de terre sont cuites à la vapeur, simplement passées dans un presse-purée avant d’être séchées jusqu’à devenir des flocons, puis cuisinées avec d’autres délicieux ingrédients. Mousline est élaboré sans colorant.',
            ]}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Faq
